import React from 'react';

export function humanizeCamelCase(str) {
  return str
    .split(/(?=[A-Z])/g)
    .map(word => `${word[0].toUpperCase()}${word.slice(1)}`)
    .join(' ');
}

export function dasherize(str) {
  return str
    .toLowerCase()
    .split(/\W/g)
    .join('-');
}

export function withNewlines(str) {
  if (typeof str !== 'string') {
    return '';
  }

  return str.split('\n').map(item => (
    <span key={item}>
      {item}
      <br />
    </span>
  ));
}
