/* eslint-disable react/destructuring-assignment */
import React from 'react';

import { humanizeCamelCase } from '../../../utils/string';
import ROUTES, { API_ROUTES } from '../../../utils/routes';
import FormError from '../../../utils/formError';

import Layout from '../../../components/layout';

import {
  Button,
  Checkbox,
  DatePicker,
  FormSuccess,
  Header,
  LayoutUI,
  Stack,
  Textarea,
  TextField,
  TextStyle
} from '../../../ui-kit';

import * as styles from './EventsForms.module.scss';

export default class FilmScreeningForm extends React.Component {
  state = {
    firstName: '',
    lastName: '',
    organization: '',
    email: '',
    phone: '',
    eventPurpose: '',
    location: '',
    eventDate: null,
    audienceSize: '',
    phShouldAttend: false,
    ownsDvd: false,
    comments: '',

    formErrors: undefined,
    formSubmitted: false,
    formSuccess: undefined
  };

  constructor(props) {
    super(props);

    this.handleInputChange = this.handleInputChange.bind(this);
    this.makeInput = this.makeInput.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  makeInput(name, label, isRequired = true) {
    const formattedLabel = label == null ? humanizeCamelCase(name) : label;

    return (
      <Stack.Item fill>
        <TextField
          label={formattedLabel}
          name={name}
          onChange={this.handleInputChange}
          value={this.state[name]}
          isRequired={isRequired}
        />
      </Stack.Item>
    );
  }

  handleInputChange(event) {
    const { target } = event;
    const { name, type } = target;
    const value = type === 'checkbox' ? target.checked : target.value;

    this.setState({
      [name]: value
    });
  }

  handleSubmit() {
    const {
      firstName,
      lastName,
      organization,
      email,
      phone,
      eventPurpose,
      location,
      eventDate,
      audienceSize,
      phShouldAttend,
      ownsDvd,
      comments
    } = this.state;

    const { path, method } = API_ROUTES.filmScreeningRequestCreate();

    this.setState({ formSubmitted: true });

    fetch(path, {
      method,
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        first_name: firstName,
        last_name: lastName,
        organization,
        email,
        phone,
        event_purpose: eventPurpose,
        event_location: location,
        event_date: eventDate,
        event_audience_size: audienceSize,
        ph_should_attend: phShouldAttend,
        owns_ph_dvd: ownsDvd,
        comments
      })
    })
      .then(response => {
        if (response.ok) {
          return response.json();
        }

        throw new FormError(response);
      })
      .then(json => {
        this.setState({
          formSuccess: (
            <FormSuccess
              name={json.first_name}
              successPhrase="Thank you for your film screening request! We'll be reaching out shortly."
              href={ROUTES.liveEvents()}
              buttonText="Go Back"
              color="orange"
            />
          )
        });
      })
      .catch(error => {
        this.setState({
          formErrors: error.formErrors,
          formSubmitted: false
        });
      });
  }

  render() {
    return this.state.formSuccess ? (
      this.state.formSuccess
    ) : (
      <Layout>
        <main>
          <LayoutUI narrow>
            <div className={styles.Heading}>
              <Stack vertical>
                <TextStyle color="orange">
                  <Header>Film Screening Request Form</Header>
                </TextStyle>
                <p className={styles.Lead}>
                  Invite Randy Taran to your event and inspire your audience to
                  bring lasting change! Fill out this form and we will contact
                  you as soon as possible.
                </p>
              </Stack>
            </div>
          </LayoutUI>

          <LayoutUI narrow>
            <Stack vertical spacing="loose">
              <Stack>
                {this.makeInput('firstName')}
                {this.makeInput('lastName')}
              </Stack>

              {this.makeInput('organization')}

              <Stack>
                {this.makeInput('email')}
                {this.makeInput('phone')}
              </Stack>
            </Stack>
          </LayoutUI>

          <LayoutUI narrow>
            <Stack vertical spacing="loose">
              <Header>Film Screening</Header>

              {this.makeInput('eventPurpose')}
              {this.makeInput('location')}

              <Stack>
                <Stack.Item fill>
                  <DatePicker
                    date={this.state.eventDate}
                    label="Event date"
                    onDateChange={date => this.setState({ eventDate: date })}
                    isRequired
                  />
                </Stack.Item>
                {this.makeInput('audienceSize', 'Approx. audience size')}
              </Stack>

              <Checkbox
                checked={this.state.phShouldAttend}
                label="I would like someone from Project Happiness to attend"
                name="phShouldAttend"
                onChange={this.handleInputChange}
              />

              <Checkbox
                checked={this.state.ownsDvd}
                label="I already own the Project Happiness DVD"
                name="ownsDvd"
                onChange={this.handleInputChange}
              />

              <Textarea
                label="Comments"
                name="comments"
                onChange={this.handleInputChange}
                value={this.state.comments}
              />

              <Stack distribution="center">
                <Button
                  color="orange"
                  onClick={this.handleSubmit}
                  disabled={this.state.formSubmitted}
                >
                  Submit
                </Button>
              </Stack>
            </Stack>
          </LayoutUI>
        </main>
      </Layout>
    );
  }
}
