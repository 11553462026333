class FormError extends Error {
  constructor(response, ...args) {
    super(...args);
    this.response = response;

    // necessary to unwrap the promise to get at the server's JSON response
    this.response.json().then(jsonData => {
      this.formErrors = jsonData;
    });

    Error.captureStackTrace(this, FormError);
  }
}

export default FormError;
